import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import classnames from "classnames";
import React, { Fragment } from "react";

export interface DialogProps {
  ariaLabel?: string;
  children?: React.ReactNode;
  classesContent?: string;
  classesOverlay?: string;
  onDismiss?(): void;
  showCloseButton?: boolean;
  showDialog: boolean;
}

export function Dialog({
  ariaLabel,
  children,
  classesContent,
  classesOverlay,
  onDismiss,
  showCloseButton = false,
  showDialog,
}: DialogProps) {
  const handleClose = onDismiss ?? (() => {});
  return (
    <Transition show={showDialog} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="fixed inset-0 z-dialog-backdrop overflow-y-auto"
        static
        open={showDialog}
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay
              className={classnames("dialog-overlay ", classesOverlay)}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classnames(
                "dialog-content transform transition-all",
                classesContent
              )}
            >
              {showCloseButton ? (
                <button
                  className="btn btn-ghost no-focus-ring absolute top-5 right-5"
                  onClick={onDismiss}
                >
                  <i className="fal fa-times fa-lg"></i>
                </button>
              ) : null}
              {children}
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition>
  );
}

export interface DialogTitleProps {
  children?: React.ReactNode;
  className?: string;
  classesTitleText?: string;
  disableTypography?: boolean;
}

export function DialogTitle({
  children,
  className,
  classesTitleText,
  disableTypography = false,
  ...other
}: DialogTitleProps) {
  return (
    <HeadlessDialog.Title
      className={classnames("dialog-title-container", className)}
      {...other}
    >
      {disableTypography ? (
        children
      ) : (
        <div
          className={classnames("dialog-title-text text-2xl", classesTitleText)}
        >
          {children}
        </div>
      )}
    </HeadlessDialog.Title>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
export function DialogActions({ className = "", ...other }) {
  return (
    <div
      className={classnames("dialog-actions-container flex", className)}
      {...other}
    />
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
export function DialogOverhead({ className = "", ...other }) {
  return (
    <div
      className={classnames("split-above-centered absolute", className)}
      {...other}
    />
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
export function DialogBody({ className = "", ...other }) {
  return (
    <div
      className={classnames("dialog-body-container", className)}
      {...other}
    />
  );
}

//  <Reach.DialogOverlay
//    className={classnames("dialog-overlay flex center", classesOverlay)}
//    isOpen={showDialog}
//    onDismiss={onDismiss}
//  >
//    <Reach.DialogContent
//      aria-label={ariaLabel}
//      className={classnames("dialog-content relative rounded", classesContent)}
//    ></Reach.DialogContent>
//  </Reach.DialogOverlay>;
